import React from "react";
import { WhySuprieveContent } from "../../../organisms/careers/WhySuprieveContent";
import { RecruitNewbiewsLayout } from "../../../organisms/careers/RecruitNewbiewsLayout";
import { WhySuprieveHeader } from "../../../organisms/menus/careers/new/WhySuprieveHeader";
import { graphql } from "gatsby";

export default ({ data }) => <WhySuprieveContent data={data} />;

export const query = graphql`
  query {
    images: allFile(
      filter: { relativePath: { regex: "/career_whysuprieve/workplace/" } }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
          relativePath
        }
      }
    }
  }
`;
